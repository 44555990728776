import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "~layouts/main";
import SEO from "~utils/seo";
import PageTitle from "~components/PageTitle";
import PreFooter from "~components/PreFooter";
import ClientList from "~components/ClientList";

export const query = graphql`
  query WorkPageQuery {
    clients: allSanityClient {
      edges {
        node {
          link
          name
          description
          preview {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

const WorkPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Work | Paonix"
        description="We use the JAMstack to develop personal portfolios, intricate e-commerce platforms, and everything in between."
      />
      <PageTitle
        title="What we've done."
        subtitle="We'd love to work on any project, big or small."
      />
      <section className="p-16">
        <ClientList clients={data.clients.edges.map(n => n.node)} />
      </section>
      <PreFooter title="Wondering what we do?">
        <p>
          Go to the{" "}
          <Link className="button" to="/services">
            services
          </Link>{" "}
          page to find out.
        </p>
      </PreFooter>
    </Layout>
  );
};

export default WorkPage;
